import React from "react";
import Kernel from "./Kernel";

import { CloudinaryContext } from "cloudinary-react";

import { ChakraProvider } from "@chakra-ui/react";
import theme from "../theme";
import { createContext } from "react";

export const NucleusContext = createContext();

const NucleusCore = (props) => {
  const { routes, config, links, errors } = props;

  return (
    <NucleusContext.Provider value={{ routes, config, links, errors }}>
      <ErrorBoundary>
        <CloudinaryContext
          cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
        >
          <ChakraProvider theme={theme}>
            <Kernel />
          </ChakraProvider>
        </CloudinaryContext>
      </ErrorBoundary>
    </NucleusContext.Provider>
  );
};

export default NucleusCore;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
