import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

const NoPermission = () => {
    return (
        <Flex w='100%' h='80vh' align='center' justify='center' p='30px'>
            <Text fontSize='24px'>
                You do not have permission to view this page
            </Text>
        </Flex>
    );
};

export default NoPermission;
