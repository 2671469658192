import React, { useContext } from "react";
import { Switch } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import routes from "./routes";
import PrivateRoute from "./Private";
import PublicRoute from "./Public";
import Menu from "../components/Menu";
import SideBar from "../components/Sidebar";
import MobileHeader from "../components/MobileHeader";

import { NucleusContext } from "../Core";
import { authToken } from "../helpers";

const Routes = ({ clientRoutes }) => {
  const { links } = useContext(NucleusContext);
  const isLoggedIn = /*authToken*/ true;

  return (
    <React.Fragment>
      {isLoggedIn && <MobileHeader />}
      <Flex w='100vw'>
        {/*isLoggedIn && <SideBar />
        {isLoggedIn && <Menu links={links} />*/}

        <Flex
          direction='column'
          w='100%'
          borderLeft='1px'
          borderColor='gray.300'
        >
          <Switch>
            {isLoggedIn &&
              clientRoutes?.map((route, index) => {
                if (route.auth) {
                  return <PrivateRoute key={`route-${index}`} {...route} />;
                }
                return <PublicRoute key={`route-${index}`} {...route} />;
              })}
            {routes.map((route, index) => {
              if (route.auth) {
                return <PrivateRoute key={`route-${index}`} {...route} />;
              }
              return <PublicRoute key={`route-${index}`} {...route} />;
            })}
          </Switch>
        </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default Routes;
