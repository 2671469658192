import React from "react";

import { LinkButton, ListWrapper } from "../../Nucleus";
import { Box, Button, Text } from "@chakra-ui/react";
import setup from "./setup";
import { gql } from "@apollo/client";

const FETCHDATA = gql`
  query ListUsers(
    $filter: FilterFindManyPermissionRoleInput
    $sort: SortFindManyPermissionRoleInput
    $page: Int
    $perPage: Int
  ) {
    listPermissionRoles(
      filter: $filter
      sort: $sort
      page: $page
      perPage: $perPage
    ) {
      items {
        _id
        title
        description
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const DELETE = gql`
  mutation Mutation($input: [String]) {
    userDelete(input: $input) {
      total
    }
  }
`;

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const List = () => {
  const columns = [
    {
      Header: "Name",
      accessor: "title",
      id: "_id",
      disableSortBy: true,
      Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant="link" size="sm" color="secondary" rounded="md">
            {row.original.title} {row.original.first_name}{" "}
            {row.original.last_name}
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      disableSortBy: true,
      Cell: ({ value }) => <Text>{value}</Text>,
    },
  ];

  const filters = [
    // {
    //     value: 'profession',
    //     type: 'select',
    //     label: 'Profession',
    //     items: data?.listProfessions?.items.map((e) => ({
    //         label: e.title,
    //         value: e._id,
    //     })),
    //     selectValue: 'value',
    // },
    // {
    //     value: 'last_name',
    //     type: 'text',
    //     label: 'Last Name',
    //     isOperator: false,
    // },
    // {
    //    value: 'test',
    //    type: 'select',
    //    isMulti: true,
    //    isOperator: true,
    //    operators: [
    //       {
    //          label: 'Includes',
    //          value: 'in'
    //       },
    //       {
    //          label: 'Excludes',
    //          value: 'nin'
    //       }
    //    ],
    //    label: 'Operator',
    //    items: [
    //       {
    //          value: 'mattprice1@me.com',
    //          label: 'Matt Price'
    //       },
    //       {
    //          value: '665dgsgffd',
    //          label: 'This is another amazing ID'
    //       }
    //    ],
    //    selectValue: 'value'
    // }
  ];

  return (
    <Box>
      <ListWrapper
        setup={setup}
        columns={columns}
        extraMenu={extraMenu}
        gqlFetch={FETCHDATA}
        gqlDelete={DELETE}
        defaultFilter={null}
        defaultSort={{}}
        filters={filters}
        showTopPagination={false}
      />
    </Box>
  );
};

export default List;
