const modelName = 'Administrators';
const modelNameSingular = 'Administrator';
const endpoint = '/admin-users';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: true,
    canAddNew: true,
    canSearch: false,
    canSelect: true,
    endpoint,
    accessor: '_id',
};

export default setup;
