import React, { useMemo } from "react";
import { AutoFields, EditView } from "../../Nucleus";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import setup from "./setup";

const FETCH = gql`
  query GetUserById($id: MongoID!) {
    userById(_id: $id) {
      firstname
      surname
      email
      password
      token
      branch {
        name
        images
        _id
      }
      _id
    }
  }
`;

const GET_BRANCHES = gql`
  query GetAllBranches {
    branchPagination {
      items {
        name
        _id
      }
    }
  }
`;
const EDIT = gql`
  mutation Mutation(
    $record: UpdateOneuserInput!
    $skip: Int
    $sort: SortUpdateOneuserInput
    $filter: FilterUpdateOneuserInput
  ) {
    userUpdateOne(record: $record, skip: $skip, sort: $sort, filter: $filter) {
      recordId
      record {
        firstname
        surname
        email
        password
        token
        branch {
          name
          images
          _id
        }
        _id
      }
    }
  }
`;

const CREATE = gql`
  mutation Mutation($record: CreateOneuserInput!) {
    userCreateOne(record: $record) {
      record {
        firstname
        surname
        email
        password
        token
        _id
        branch {
          name
          images
          _id
        }
      }
      recordId
    }
  }
`;

const EditForm = () => {
  const [fetchData, { data }] = useLazyQuery(FETCH);
  const { data: Branches, loading, error } = useQuery(GET_BRANCHES);

  const fields = useMemo(
    () => [
      {
        group: "",
        fields: [
          {
            name: "firstname",
            component: "text",
            placeholder: "John",
            label: "First Name",
            required: true,
            rules: { required: true },
          },
          {
            name: "surname",
            component: "text",
            label: "Last name",
            placeholder: "Smith",
          },
          {
            name: "email",
            component: "text",
            label: "Email",
            placeholder: "Email@email.com",
          },
          {
            name: "branch",
            component: "select",
            selectValue: "value",
            label: "Branch",
            placeholder: "Select branch",
            defaultVaule: data?.userById?.branch?.name,
            options: loading ? [] : Branches?.branchPagination?.items,
            labelKey: "name",
            valueKey: "_id",
            rules: { required: true },
          },
        ],
      },
    ],
    [Branches, data]
  );
  return (
    <EditView
      setup={setup}
      fetchData={fetchData}
      data={data}
      gqlFetch={FETCH}
      gqlEdit={EDIT}
      gqlCreate={CREATE}
      gqlBranches={GET_BRANCHES}
    >
      <AutoFields fields={fields} />
      {/* <UserForm /> */}
    </EditView>
  );
};

export default EditForm;
