const Button = {
  variants: {
    primary: (props) => ({
      bg: props.colorMode === "dark" ? "white" : "primary",
      color: props.colorMode === "dark" ? "primary" : "grey",
      _hover: {
        bg: props.colorMode === "dark" ? "rgba(255,255,255,0.8)" : "#2D2F31",
      },
    }),
    success: () => ({
      bg: "success",
      color: "white",
      _hover: {
        bg: "#229652",
      },
    }),
  },
};

export default Button;
