import React from "react";
import { Field } from "../../components/Form";
import { Box, Button, Container } from "@chakra-ui/react";
import EditView from "../../components/EditView";
import setup from "./setup";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useLazyQuery, useQuery, gql } from "@apollo/client";
import { useState } from "react";

const FETCH = gql`
  query AdminUserById($id: MongoID!) {
    adminUserById(_id: $id) {
      firstName
      lastName
      email
    }
  }
`;

const FETCH_DATA = gql`
  query FetchUserData {
    listAdminRoles {
      items {
        _id
        title
      }
    }
  }
`;

const EDIT = gql`
  mutation EditAdminUser(
    $record: UpdateOneAdminUserInput!
    $filter: FilterUpdateOneAdminUserInput!
  ) {
    editAdminUser(record: $record, filter: $filter) {
      _id
    }
  }
`;

const CREATE = gql`
  mutation CreateAdminUser($record: CreateOneAdminUserInput!) {
    createAdminUser(record: $record) {
      _id
    }
  }
`;

const EditForm = () => {
  const { type } = useParams();

  const [fetchData, { data }] = useLazyQuery(FETCH);
  const { data: userData } = useQuery(FETCH_DATA);

  const useValidation = () => {
    if (type === "create") {
      return yup.object().shape({
        name: yup.string().required("Required"),
        email: yup.string().email().required("Required"),
        password: yup.string().min(8).required("Required"),
        password_confirm: yup.string().when("password", {
          is: (val) => val && val.length > 0, // alternatively: (val) => val == true
          then: yup
            .string()
            .required("You must confirm your password")
            .oneOf([yup.ref("password")], "Passwords don't match"),
        }),
      });
    }
    if (type === "edit") {
      return yup.object().shape({
        name: yup.string().required("Required"),
        email: yup.string().email().required("Required"),
        password: yup.string("Required"),
        password_confirm: yup.string().when("password", {
          is: (val) => val && val.length > 0, // alternatively: (val) => val == true
          then: yup
            .string()
            .required("You must confirm the password")
            .oneOf([yup.ref("password")], "Passwords don't match"),
        }),
      });
    }
  };

  return (
    <EditView
      setup={setup}
      fetchData={fetchData}
      data={data}
      //   validationSchema={useValidation()}
      gqlFetch={FETCH}
      gqlCreate={CREATE}
      gqlEdit={EDIT}
      isFullWidth
    >
      <Container
        maxWidth="600px"
        w="100%"
        p="20px"
        margin=" 25px auto"
        variant="card"
      >
        <Field
          isFullWidth
          name="firstName"
          component="text"
          label="First name"
          rules={{ required: true }}
        />
        <Field
          isFullWidth
          name="lastName"
          component="text"
          label="Last name"
          rules={{ required: true }}
        />
        {/* <Field
          auto
          name="role"
          component="select"
          options={
            userData?.listAdminRoles ? userData?.listAdminRoles?.items : []
          }
          label="Role"
          labelKey="title"
          valueKey="_id"
          selectValue="value"
          rules={{ required: true }}
        /> */}
        <Field
          isFullWidth
          name="email"
          component="text"
          type="email"
          label="Email Address"
          rules={{ required: true }}
        />

        {/* <Box mt="25px">
          <Field
            isFullWidth
            name="password"
            autoComplete="off"
            component="text"
            label={type === "create" ? "Password" : "Change Password"}
            rules={{ required: true }}
          />
          <Field
            isFullWidth
            name="password_confirm"
            autoComplete="address"
            component="text"
            label={
              type === "create" ? "Confirm Password" : "Confirm New Password"
            }
            rules={{ required: true }}
          />
        </Box> */}
      </Container>
    </EditView>
  );
};

export default EditForm;
