import { extendTheme } from "@chakra-ui/react";

/// Palette
import colors from "./colors";

/// Components
import Button from "./components/button";
import Input from "./components/input";
import Container from "./components/container";
import Textarea from "./components/textarea";
import Select from "./components/select";
import NumberInput from "./components/number";

const overrides = {
  styles: {
    global: ({ colorMode }) => ({
      "html, body": {
        fontSize: "14px",
        color: "black",
        bg: colorMode === "light" ? "white" : "gray.900",
        overflowX: "hidden",
      },
    }),
  },
  colors,
  components: {
    Input,
    Button,
    Container,
    Textarea,
    Select,
    NumberInput,
  },
  config: {
    cssVarPrefix: "nucleus",
    initialColorMode: "light",
    useSystemColorMode: false,
  },
};
export default extendTheme(overrides);
