import jwt_decode from "jwt-decode";

/* global localStorage, */

const bypassAuth = process.env.REACT_APP_BYPASS_AUTH === "true" ? true : false;

export const generateSlugFromSite = () => {
  const siteName = process.env.REACT_APP_SITENAME;
  return siteName.split(" ").join("_");
};

/// ///// Authentication Helpers

export const authToken = () => {
  if (localStorage.getItem(`${generateSlugFromSite()}-token`)) {
    return jwt_decode(localStorage.getItem(`${generateSlugFromSite()}-token`));
  } else {
    return false;
  }
};

export const hasAuthToken = () => (bypassAuth ? true : !!authToken);

export const checkAuthTokenExpiry = () => {
  const token = localStorage.getItem(`${generateSlugFromSite()}-token`);
  const decodedToken = token ? jwt_decode(token) : null;
  if (decodedToken && isStillValid(decodedToken?.exp)) {
    return true;
  } else {
    logout();
  }

  // if (bypassAuth) {
  //   return true;
  // } else {
  //   const token = localStorage.getItem(`${generateSlugFromSite()}-token`);
  //   const decodedToken = token ? jwt_decode(token) : null;
  //   if (decodedToken && isStillValid(decodedToken?.exp)) {
  //     return true;
  //   } else {
  //     window.location = "/";
  //     localStorage.removeItem(`${generateSlugFromSite()}-token`);
  //     return false;
  //   }
  // }
};

export const isStillValid = (date) => {
  // return false;
  const now = new Date();
  if (now.getTime() / 1000 <= date) {
    return true;
  } else {
    return false;
  }
};

export const saveSiteToStorage = (sites) => {
  const hasSiteSaved = localStorage.getItem(`${generateSlugFromSite()}-site`);
  if (!hasSiteSaved) {
    localStorage.setItem(
      `${generateSlugFromSite()}-site`,
      JSON.stringify(sites[0])
    );
    window.location.reload();
  }
};

export const getSite = JSON.parse(
  localStorage.getItem(`${generateSlugFromSite()}-site`)
);

export const logout = () => {
  localStorage.removeItem(`${generateSlugFromSite()}-token`);
  window.location.href = "/";
};

export const checkPermissions = ({ has = [], required = [] }) => {
  //   let hasPermission;

  if (authToken()) {
    return true;
  }

  //   authToken
  //   if (required) {
  //     required.forEach((r) => {
  //       if (has.some((e) => e.code === r)) {
  //         hasPermission = true;
  //       }
  //     });
  //   }
  //   //const hasPermission = has.some((e) => e.code === required);
  //   const hasAll = has.some((e) => e.code === "ALL");

  //   if (hasAll === true) {
  //     return true;
  //   }

  //   if (hasPermission === true) {
  //     return true;
  //   }
  //   return false;
};

/// ///// Form Helpers

export const saveFormToStorage = ({ path, values }) => {
  localStorage.setItem(path, JSON.stringify(values));
};

export const getFormFromStorage = ({ path, values }) => {
  return JSON.parse(localStorage.getItem(path));
};

// General

export const downloadFile = (url) => {
  var a = document.createElement("a");
  a.href = url;
  const fileName = url.split("/").pop();
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};
