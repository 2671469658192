import React from 'react'
import { Input } from '@chakra-ui/react'

const Text = (props) => {
  const { onFocus, onChange, onBlur, value, children, type, ...rest } = props
  return (
    <Input
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => {
        onBlur && onBlur()
      }}
      onFocus={() => {
        onFocus && onFocus()
      }}
      value={value}
      required={false}
      isRequired={false}
      minWidth={type === 'number' ? '100px' : '200px'}
      type={type}
      {...rest}
    />
  )
}

export default Text
