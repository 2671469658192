import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Field } from '../../components/Form';
import Controls from './Controls';
import { GrPowerCycle } from 'react-icons/gr';

const TextWithImage = ({ field, index, align }) => {
    const { setValue, watch } = useFormContext();
    const direction = watch(`blocks.${index}.align`);

    const changeDirection = () => {
        setValue(
            `blocks.${index}.align`,
            direction === 'left' ? 'right' : 'left'
        );
    };

    return (
        <Flex
            w='100%'
            direction='column'
            align='flex-start'
            rounded='5px'
            bg='white'
            p='15px'
            mb='15px'
        >
            <Flex w='100%' mb='5px'>
                <Controls index={index} />
                <Button
                    size='sm'
                    ml='auto'
                    fontWeight='normal'
                    leftIcon={<GrPowerCycle />}
                    onClick={() => changeDirection()}
                >
                    Switch Direction
                </Button>
            </Flex>
            <Flex
                w='100%'
                direction={direction === 'left' ? 'row' : 'row-reverse'}
            >
                <Flex flex={1}>
                    <Field
                        component='file'
                        name={`blocks.${index}.image`}
                        max={1}
                    />
                </Flex>
                <Flex
                    direction='column'
                    w='100%'
                    ml={direction === 'left' ? '20px' : '0px'}
                    mr={direction === 'right' ? '20px' : '0px'}
                    flex={2}
                >
                    <Field
                        name={`blocks.${index}.heading`}
                        component='text'
                        placeholder='Heading'
                    />
                    <Field
                        name={`blocks.${index}.text`}
                        component='richtext'
                        placeholder='Text Content'
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default TextWithImage;
