import { Button, Stack, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useContext } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { HiOutlineTrash } from 'react-icons/hi';
import { PageEditorContext } from '.';
import PopConfirm from '../../components/PopConfirm';

const Controls = ({ index }) => {
    const { onOpen, setActiveIndex, remove } = useContext(PageEditorContext);

    return (
        <Stack
            isInline
            spacing='15px'
            rounded='4px'
            bg='gray.200'
            py='5px'
            px='10px'
            mb='10px'
        >
            <Tooltip label='Insert Block Above'>
                <Button
                    variant='link'
                    size='sm'
                    fontWeight='normal'
                    colorScheme='black'
                    leftIcon={<MdAddCircle />}
                    onClick={() => {
                        setActiveIndex(index === 0 ? 0 : index - 1);
                        onOpen();
                    }}
                >
                    Add Block Above
                </Button>
            </Tooltip>
            <Tooltip label='Insert Block Below'>
                <Button
                    variant='link'
                    size='sm'
                    fontWeight='normal'
                    colorScheme='black'
                    leftIcon={<MdAddCircle />}
                    onClick={() => {
                        setActiveIndex(index + 1);
                        onOpen();
                    }}
                >
                    Add Block Below
                </Button>
            </Tooltip>
            <Tooltip label='Delete Block'>
                <PopConfirm
                    onConfirm={() => remove(index)}
                    title='Are you sure you want to remove this block?'
                >
                    <Button
                        variant='link'
                        size='sm'
                        fontWeight='normal'
                        colorScheme='black'
                        leftIcon={<HiOutlineTrash />}
                    >
                        Delete Block
                    </Button>
                </PopConfirm>
            </Tooltip>
        </Stack>
    );
};

export default Controls;
