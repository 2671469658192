import React from "react";
import { LinkButton, ListWrapper } from "../../Nucleus";
import { Box, Button, Text } from "@chakra-ui/react";
import setup from "./setup";
import { gql } from "@apollo/client";
import { FiEdit } from "react-icons/fi";
import Help from "../../utils/Help";

const FETCHDATA = gql`
  query GetEverything(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyuserInput
    $sort: SortFindManyuserInput
  ) {
    userPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        firstname
        surname
        email
        password
        token
        branch {
          name
          images
          _id
        }
        _id
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const DELETE = gql`
  mutation Mutation($input: [String]) {
    userDelete(input: $input) {
      total
    }
  }
`;
const CREATE = gql`
  mutation Mutation($record: CreateOneuserInput!) {
    userCreateOne(record: $record) {
      record {
        firstname
        surname
        email
        password
        token
        _id
        branch {
          name
          images
          _id
        }
      }
      recordId
    }
  }
`;

const List = () => {
  const columns = [
    {
      accessor: "_id",
      id: "_id",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <LinkButton
            to={`${setup.model}/edit/${row.original[setup.accessor]}`}
          >
            <Button
              variant="link"
              size="sm"
              color="secondary"
              rounded="md"
              fontSize="18px"
            >
              <FiEdit />
            </Button>
          </LinkButton>
        );
      },
    },
    {
      Header: "First Name",
      accessor: "firstname",
    },
    {
      Header: "Last Name",
      accessor: "surname",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Branch",
      accessor: "branch.name",
    },
  ];

  const filters = [
    {
      value: "firstname",
      type: "text",
      label: "First Name",
      isOperator: false,
    },
    {
      value: "surname",
      type: "text",
      label: "Last Name",
      isOperator: false,
    },
    {
      value: "email",
      type: "text",
      label: "Email",
      isOperator: false,
    },
  ];

  return (
    <Box>
      <ListWrapper
        setup={setup}
        columns={columns}
        gqlFetch={FETCHDATA}
        gqlDelete={DELETE}
        gqlCreate={CREATE}
        defaultSort={{}}
        defaultFilter={{}}
        filters={filters}
        showTopPagination={false}
      />
    </Box>
  );
};

// defaultFilter={{ is_verified: true }}

export default List;
