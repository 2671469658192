import React from 'react';
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';

const Number = (props) => {
    const { onChange, onBlur, value, children, type, ...rest } = props;
    return (
        <NumberInput
            onChange={(e) => onChange(parseInt(e))}
            onBlur={() => {
                onBlur && onBlur();
            }}
            value={value ? value : ''}
            required={false}
            isRequired={false}
            minWidth={type === 'number' ? '100px' : '200px'}
            type={type}
            {...rest}
        >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    );
};

export default Number;
