import React, { useCallback, useEffect, useState, memo } from "react";

import { FiTrash, FiPlus } from "react-icons/fi";

import TableLoader from "../common/TableLoader";
import { useRecoilState } from "recoil";

import {
  Box,
  Stack,
  Flex,
  Text,
  Button,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";
import MoreOptions from "../EditView/MoreOptions";
import PopConfirm from "../../components/PopConfirm";
import Table from "./GraphQLTable";

import LinkButton from "../LinkButton";
import { useLazyQuery, useMutation } from "@apollo/client";

import SearchInput from "./SearchInput";

import { tableAtom } from "../../state/global";
import { tableFilterAtom } from "./atom";

import OperatorSearchSelect from "./OperatorSearchSelect";

import NoPermission from "../NoPermission";

import { checkAuthTokenExpiry } from "../../helpers";

const MemoTable = memo(
  ({
    setup,
    columns,
    data = null,
    error,
    loading,
    onChangePageSize,
    onChangePage,
    onRowSelect,
    onSortSelect,

    ...rest
  }) => {
    return (
      !error && (
        <Box h="100%" maxHeight="100%">
          {!loading ? (
            (data && data.data?.length) ||
            (data && data.data.items && data.data.items.length !== 0) ? (
              <Table
                accessor={setup.accessor}
                columns={columns}
                data={data}
                loading={loading}
                onChangePageSize={onChangePageSize}
                onChangePage={onChangePage}
                onRowSelect={onRowSelect}
                onSortSelect={onSortSelect}
                size="sm"
                {...rest}
              />
            ) : (
              <Flex w="100%" h="500px" align="center" justify="center">
                <Text fontSize="1.8rem">No results found</Text>
              </Flex>
            )
          ) : (
            <Box p="20px">
              <TableLoader />
            </Box>
          )}
        </Box>
      )
    );
  }
);

MemoTable.displayName = "MemoTable";

const TableComp = ({
  setup,
  columns,
  extraMenu = null,
  filters,
  handleRowSelection = null,
  showTopPagination = true,
  hidePagination = false,
  gqlFetch,
  gqlDelete,
  defaultFilter,
  permissions,
  ...rest
}) => {
  const border = useColorModeValue("gray.200", "gray.700");

  const [dataFilters, setFilters] = useRecoilState(tableFilterAtom);
  const [tableState, setTableState] = useRecoilState(tableAtom);
  const ExtraComponent = extraMenu;
  const [selected, setSelected] = useState([]);
  /* eslint-disable  */
  const [fetchData, { data, loading, error }] = useLazyQuery(gqlFetch);

  useEffect(() => {
    setFilters(defaultFilter);
  }, []);

  useEffect(() => {
    fetchData({
      variables: {
        filter: dataFilters,
        sort: tableState.params.sort,
        page: tableState.params.page,
        perPage: tableState.params.pagination,
      },
      fetchPolicy: "network-only",
    });
  }, [dataFilters, tableState]);

  const onChangePageSize = useCallback(
    (size) => {
      setTableState((old) => ({
        ...old,
        params: { ...old.params, pagination: parseInt(size) },
      }));
    },
    [setTableState]
  );

  const onChangePage = useCallback(
    (page) => {
      setTableState((old) => ({
        ...old,
        params: { ...old.params, page: parseInt(page) },
      }));
    },
    [setTableState]
  );

  const handleRowSelect = useCallback(
    (rows) => {
      setSelected(
        rows.length !== 0 ? rows.map((d) => d.original[setup.accessor]) : []
      );
      setTableState((old) => ({
        ...old,
        selected:
          rows.length !== 0 ? rows.map((d) => d.original[setup.accessor]) : [],
        selectedRows: rows.length !== 0 ? rows.map((d) => d.original) : [],
      }));
      if (handleRowSelection) {
        handleRowSelection(rows);
      }
    },
    [setTableState]
  );

  const onSortSelect = useCallback(
    (filter) => {
      console.log(filter);
      if (filter[0]) {
        const model = filter[0].id.toUpperCase();
        const direction = filter[0].desc ? "DESC" : "ASC";
        const sort = `${model}_${direction}`;
        setTableState((old) => ({
          ...old,
          params: {
            ...old.params,
            sort: sort,
          },
        }));
      } else {
        setTableState((old) => ({
          ...old,
          params: {
            ...old.params,
            sort: null,
          },
        }));
      }
    },
    [setTableState]
  );

  const [deleteItems, { data: deleteResponse, loading: deleteLoading }] =
    useMutation(gqlDelete);

  const onDelete = () => {
    deleteItems({ variables: { input: tableState.selected } });
  };

  useEffect(() => {
    if (deleteResponse) {
      window.location.reload();
    }
  }, [deleteResponse]);

  const setActiveFilters = (fil) => {
    setFilters({ ...defaultFilter, ...fil });
  };

  /* eslint-enable */

  const changeSearch = (value) => {
    setFilters((old) => ({ ...old, search: value }));
  };
  if (!checkAuthTokenExpiry()) {
    return <NoPermission />;
  }

  return (
    <Flex height="auto" direction="column">
      <Container
        w="100%"
        maxWidth="none"
        top={0}
        px={0}
        position="sticky"
        zIndex={1000}
        variant="card"
        rounded={0}
        boxShadow="none"
      >
        <Flex
          w="100%"
          h="60px"
          borderBottom="1px"
          align="center"
          borderColor={border}
          position="relative"
          zIndex={1000}
          px={5}
        >
          <Text as="h1" fontWeight="semibold" fontSize="18px">
            {setup.title}
          </Text>

          <Flex ml="auto" align="center">
            {extraMenu && (
              <Box mr="20px">
                <ExtraComponent />
              </Box>
            )}

            {setup.canAddNew === true && (
              <Box>
                <LinkButton to={`${setup.model}/create`}>
                  <Button
                    variant="outline"
                    colorScheme="primary"
                    leftIcon={<FiPlus />}
                  >
                    Create New
                  </Button>
                </LinkButton>
              </Box>
            )}
          </Flex>
        </Flex>
        <Flex borderBottom="1px" borderColor={border} align="center">
          <Stack
            isInline
            pt="8px"
            pb="8px"
            align="center"
            spacing="5px"
            pl="8px"
          >
            {setup.canSearch && <SearchInput changeSearch={changeSearch} />}
            {setup.canFilter && filters.length !== 0 && (
              <Stack isInline align="center" spacing="5px" pl="5px">
                <OperatorSearchSelect
                  size="xs"
                  filters={filters}
                  onChange={(v) => setActiveFilters(v)}
                />
              </Stack>
            )}

            <Box>
              {setup.canDelete && setup.canSelect && selected.length !== 0 && (
                <PopConfirm
                  okText="Delete"
                  onConfirm={() => onDelete()}
                  okButtonProps={{
                    variantColor: "error",
                  }}
                  cancelButtonProps={{
                    variantColor: "gray",
                  }}
                  title="Are you sure you want to delete these entries?  This cannot be undone."
                >
                  <Button
                    leftIcon={<FiTrash />}
                    variant="ghost"
                    size="sm"
                    variantColor="error"
                  >
                    Delete {selected.length} items
                  </Button>
                </PopConfirm>
              )}
            </Box>
          </Stack>
          {/* <Box ml='auto' pr='15px'>
                  {extraMenu && <ExtraComponent />}
               </Box> */}
        </Flex>
      </Container>

      <MemoTable
        accessor={setup.accessor}
        columns={columns}
        data={{ data: data ? Object.values(data)[0] : [] }}
        setup={setup}
        loading={loading}
        error={error}
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
        onRowSelect={
          setup.canSelect === undefined
            ? handleRowSelect
            : setup.canSelect
            ? handleRowSelect
            : null
        }
        onSortSelect={onSortSelect}
        size="sm"
        showTopPagination={showTopPagination}
        hidePagination={hidePagination}
        {...rest}
      />
    </Flex>
  );
};

TableComp.displayName = "ListView";

export default TableComp;
