import React from "react";
import { Spinner } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { authAtom } from "../../state/auth";


const AuthChecker = ({ children, isPrivate }) => {

  const [auth, setAuth] = useRecoilState(authAtom);


  return auth.loading ? <Spinner /> : children;
};

export default AuthChecker;
