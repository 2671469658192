const modelName = 'User Groups';
const modelNameSingular = 'User Group';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: false,
    canAddNew: false,
    canSearch: false,
    canFilter: false,
    canSelect: false,
    accessor: '_id',
};

export default setup;
