import React, { useMemo } from 'react';
import { AutoFields, EditView } from '../../Nucleus';
import { gql, useLazyQuery } from '@apollo/client';
import setup from './setup';

const FETCH = gql`
    query Query($id: MongoID!) {
        getPermissionRole(_id: $id) {
            _id
            title
            description
            countries
        }
        listCountries {
            name
            code
        }
    }
`;

const EDIT = gql`
    mutation Mutation(
        $record: UpdateOnePermissionRoleInput!
        $filter: FilterUpdateOnePermissionRoleInput!
    ) {
        editPermissionRole(record: $record, filter: $filter) {
            recordId
        }
    }
`;

const CREATE = gql`
    mutation Mutation($record: CreateOnePermissionRoleInput!) {
        createPermissionRole(record: $record) {
            recordId
        }
    }
`;

const EditForm = () => {
    const [fetchData, { data }] = useLazyQuery(FETCH, {
        fetchPolicy: 'network-only',
    });
    const fields = useMemo(
        () => [
            {
                group: 'Main Details',
                tab: 'Main Details',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                        required: true,
                        rules: { required: true },
                    },
                    {
                        name: 'description',
                        component: 'textarea',
                        placeholder: 'Description',
                        label: 'Description',
                        rows: 2,
                    },
                    {
                        name: 'countries',
                        component: 'select',
                        placeholder: 'Countries',
                        label: 'Countries',
                        isMulti: true,
                        help: 'Select the countries that this role applies to',
                        options: data ? data.listCountries : [],
                        selectValue: 'value',
                        labelKey: 'name',
                        valueKey: 'code',
                        required: true,
                        rules: { required: true },
                    },
                ],
            },
        ],
        [data]
    );

    return (
        <EditView
            fetchData={fetchData}
            data={data}
            setup={setup}
            gqlFetch={FETCH}
            gqlEdit={EDIT}
            gqlCreate={CREATE}
        >
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
