import React, { useEffect } from "react";
import { NucleusCore, theme } from "./Nucleus";
import { createUploadLink } from "apollo-upload-client";

import config from "./config";
import routes from "./routes";
import links from "./links";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { authAtom } from "./Nucleus/state/auth";
import jwt_decode from "jwt-decode";
import { useRecoilState} from "recoil";

import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/char_counter.min.js";

const siteName = process.env.REACT_APP_SITENAME;

const App = () => {
  const httpLink = createUploadLink({
    uri: `${process.env.REACT_APP_API}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(
      `${siteName.split(" ").join("_")}-token`
    );

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  });

  // const client = new ApolloClient({
  //   link: createUploadLink({
  //     uri: "http://localhost:4000/graphql"
  //   }),
  //   cors: true,
  //   cache: new InMemoryCache()
  // })

  const [auth, setAuth] = useRecoilState(authAtom);
  
  useEffect(() => {
    const token = localStorage.getItem(
      `${siteName.split(" ").join("_")}-token`
    );
    if (token) {
      setAuth((old) => ({
        ...old,
        currentUser: jwt_decode(token),
        loading: false,
      }));
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <NucleusCore config={config} routes={routes} links={links} />
      </ChakraProvider>
    </ApolloProvider>
  );
};

export default App;
