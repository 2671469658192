import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Field } from '../../components/Form';
import Controls from './Controls';

const TextWithHeading = ({ field, index }) => {
    return (
        <Flex
            w='100%'
            direction='column'
            align='flex-start'
            rounded='5px'
            bg='white'
            p='15px'
            mb='15px'
        >
            <Controls index={index} />
            <Flex direction='column' w='100%'>
                <Field
                    name={`blocks.${index}.heading`}
                    component='text'
                    placeholder='Heading'
                />
                <Field
                    name={`blocks.${index}.text`}
                    component='richtext'
                    placeholder='Text Content'
                />
            </Flex>
        </Flex>
    );
};

export default TextWithHeading;
