import React, {
  memo,
  cloneElement,
  Children,
  isValidElement,
  useState,
  useEffect,
} from "react";
import { BiExit } from "react-icons/bi";

import { Form } from "../../components/Form";
import {
  Flex,
  Button,
  Text,
  Box,
  Skeleton,
  Stack,
  IconButton,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useParams, useHistory } from "react-router-dom";
import InfoBar from "./InfoBar";
import DeveloperTools from "./DeveloperTools";
import MoreOptions from "./MoreOptions";
import { useFormContext } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";

import { formAtom } from "../../state/form";
import { useRecoilState, useRecoilValue } from "recoil";
import { RiCodeSSlashLine } from "react-icons/ri";
import { useMutation } from "@apollo/client";
import { authAtom } from "../../state/auth";
import { checkPermissions, logout } from "../../helpers";
import NoPermission from "../NoPermission";

const SubmitButton = memo(({ setup }) => {
  const params = useParams();
  const { loading } = useRecoilValue(formAtom);
  const isEdit = params.type === "edit";
  const { submit } = useFormContext();

  return (
    <Button
      variant='success'
      color='white'
      onClick={() => submit()}
      isLoading={loading}
      loadingText='Submitting '
    >
      {isEdit ? `Save ${setup.singular}` : `Submit ${setup.singular}`}
    </Button>
  );
});

const FormColumn = memo((props) => {
  const { isEdit, data, children } = props;

  return (
    <Flex w='100%' h='100%' flex={1} align='center' direction='column'>
      {isEdit ? (
        data ? (
          children
        ) : (
          <Box w='90%' h='100%' maxWidth={600} mt={10}>
            <Skeleton variant='rect' h='100px' mb='5px' />
            <Skeleton variant='rect' mb='5px' />
            <Skeleton h='30px' mb='5px' />
            <Skeleton mb='5px' />
            <Skeleton variant='rect' mb='5px' />
            <Skeleton h='30px' mb='5px' />
          </Box>
        )
      ) : (
        Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, props);
          }
          return child;
        })
      )}
    </Flex>
  );
});

const EditView = (props) => {
  const {
    noListView,
    children,
    data,
    fetchData,
    setup,
    isFullWidth,
    noPadding,
    validationSchema,
    gqlCreate,
    gqlEdit,
    gqlFetch,
    ignoreValues,
    defaultValues,
    permissions,
  } = props;
  const params = useParams();

  const isView = params.type === "view";
  const isEdit = params.type === "edit";
  const isCreate = params.type === "create";
  const [devTools, setDevTools] = useState(false);
  const toast = useToast();

  const [formState, setFormState] = useRecoilState(formAtom);
  const { currentUser } = useRecoilValue(authAtom);

  // const [fetch, { data: gqlData, error }] = useLazyQuery(gqlFetch, {
  //    variables: { id: params.id },
  //    fetchPolicy: 'network-only'
  // })

  const [create, { error: createError }] = useMutation(gqlCreate);
  const [edit, { error: editError }] = useMutation(gqlEdit);

  React.useState(() => {
    setFormState((old) => ({ ...old, errors: null, success: false }));
  }, []);

  useEffect(() => {
    if ((isEdit && currentUser) || (isView && currentUser)) {
      fetchData({
        variables: { id: params.id },
      });
    }
  }, [fetchData, isEdit, isView, currentUser, params.id]);

  useEffect(() => {
    if (createError) {
      setFormState((old) => ({ ...old, errors: createError }));
    }
    if (editError) {
      setFormState((old) => ({ ...old, errors: editError }));
    }
  }, [createError, editError, setFormState]);

  /* eslint-disable */
  const onSubmit = async (values) => {
    setFormState((old) => ({ ...old, loading: true }));
    if (isEdit) {
      try {
        const original = { ...values };
        delete values._id;
        await edit({
          variables: {
            record: values,
            filter: { _id: params.id },
          },
          update: (cache) => {
            cache.writeQuery({ query: gqlFetch, broadcast: true }, original);
          },
        });
        setFormState((old) => ({
          ...old,
          loading: false,
          success: true,
        }));
      } catch (e) {
        setFormState((old) => ({
          ...old,
          loading: false,
          success: false,
          errors: e,
        }));
      }
    }
    if (isCreate) {
      try {
        await create({
          variables: { record: values },
        });
        setFormState((old) => ({
          ...old,
          loading: false,
          success: true,
        }));

        setTimeout(() => {
          window.location.href = `/${setup.model}`;
        }, 1000);
      } catch (e) {
        setFormState((old) => ({
          ...old,
          loading: false,
        }));
      }
    }
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        setFormState((old) => ({ ...old, errors: null }));
      }, 7000);
      toast({
        status: "error",
        title: "There was a problem saving this entry",
      });
    }
  }, [formState.errors]);

  useEffect(() => {
    if (formState.success === true) {
      setTimeout(() => {
        setFormState((old) => ({ ...old, success: false }));
      }, 7000);
      toast({ status: "success", title: "Entry saved successfully" });
    }
  }, [formState.success]);

  if (
    !checkPermissions({
      has: currentUser?.user?.role.permissions,
      required: permissions,
    })
  ) {
    return <NoPermission />;
  }

  return (
    <Form
      data={data && Object.values(data)[0]}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      ignoreValues={ignoreValues}
      defaultValues={defaultValues}
    >
      <Flex
        w={noPadding ? "calc(100% + 40px)" : "100%"}
        align='center'
        direction='column'
        m={noPadding ? "-20px" : "0px"}
        bg='rgba(0,0,0,0.05)'
        minHeight='100vh'
      >
        <Flex
          w='100%'
          align='center'
          px={2}
          h={50}
          flexShrink={0}
          flexGrow={0}
          borderBottom='1px'
          borderColor='gray.200'
          pos='relative'
          zIndex={1000}
          bg='white'
          position='sticky'
          top={0}
          left={0}
        >
          {!noListView && (
            <Button
              variant='ghost'
              colorScheme='gray'
              size='sm'
              opacity={0.7}
              mr={2}
              onClick={() => (window.location.href = `/${setup.model}`)}
              leftIcon={<MdKeyboardArrowLeft fontSize='30px' />}
            >
              View all {setup.model}
            </Button>
          )}

          <Text
            as='h1'
            fontSize='lg'
            fontWeight='semibold'
            ml='25px'
            display={{ base: "none", md: "inline-block" }}
          >
            {isEdit ? "Edit" : "Create"} {setup.singular}
          </Text>

          <Stack ml='auto' isInline spacing='10px' mr='10px'>
            <MoreOptions setup={setup} />
            <SubmitButton setup={setup} />
            {process.env.REACT_APP_ENVIRONMENT === "development" && (
              <IconButton
                onClick={() => setDevTools(!devTools)}
                rounded='lg'
                fontSize='16px'
                mr='0px'
              >
                <RiCodeSSlashLine />
              </IconButton>
            )}
            <Tooltip label='Logout' placement='right'>
              <IconButton
                rounded='full'
                size='lg'
                variant='ghost'
                onClick={() => logout()}
                mt='auto'
                icon={<BiExit fontSize={20} />}
              />
            </Tooltip>
          </Stack>
        </Flex>

        <ErrorMessage />

        <Flex w='100%' pb='50px' flex={1} justify='flex-start'>
          <FormColumn
            data={data ? Object.values(data)[0] : null}
            isEdit={isEdit}
            isView={isView}
            children={children}
            isFullWidth={isFullWidth}
          />
          <InfoBar />

          {process.env.REACT_APP_ENVIRONMENT === "development" && (
            <Flex
              direction='column'
              w='100%'
              h='100%'
              overflowY='scroll'
              maxWidth='350px'
              flexGrow={0}
              bg='#1a1a1e'
              position='fixed'
              right={0}
              zIndex={8000}
              top={0}
              willChange='transform'
              transition='all 0.3s ease'
              transform={devTools ? "translateX(0%)" : "translateX(100%)"}
            >
              {devTools && (
                <DeveloperTools setDevTools={setDevTools} devTools={devTools} />
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Form>
  );
};

EditView.defaultProps = {
  isFullWidth: true,
};

export default EditView;
