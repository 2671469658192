import React from 'react';
import { Route } from 'react-router-dom';
import AuthChecker from '../components/AuthChecker';
import Page from '../components/Page';

const PrivateRoute = ({ component: Component, title, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <AuthChecker isPrivate>
                    <Page title={title} auth>
                        <Component {...props} />
                    </Page>
                </AuthChecker>
            )}
        />
    );
};

export default PrivateRoute;
