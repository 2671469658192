import React from 'react';
import { Flex, useDisclosure, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import SelectModal from './SelectModal';
import TextWithHeading from './TextWithHeading';
import { createContext } from 'react';
import TextWithImage from './TextWithImage';

export const PageEditorContext = createContext();

const PageEditor = ({ value, onChange }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activeIndex, setActiveIndex] = useState();
    const { fields, insert, remove } = useFieldArray({
        name: 'blocks',
    });

    const addBlock = (type) => {
        if (type === 'textWithHeading') {
            insert(activeIndex, {
                type: type,
                heading: '',
                text: '',
            });
        }
        if (type === 'textImageLeft') {
            insert(activeIndex, {
                type: type,
                align: 'left',
                heading: '',
                text: '',
                image: null,
            });
        }
        if (type === 'textImageRight') {
            insert(activeIndex, {
                type: type,
                align: 'right',
                heading: '',
                text: '',
                image: null,
            });
        }
        onClose();
    };

    return (
        <PageEditorContext.Provider
            value={{ onOpen, setActiveIndex, insert, remove }}
        >
            <Flex w='100%' direction='column'>
                {fields && fields.length === 0 && (
                    <Button
                        onClick={() => {
                            setActiveIndex(0);
                            onOpen();
                        }}
                    >
                        Add New Block
                    </Button>
                )}

                <Flex bg='gray.100' rounded='5px' p='10px' direction='column'>
                    {fields &&
                        fields.map((field, i) => {
                            switch (field.type) {
                                case 'textWithHeading':
                                    return (
                                        <TextWithHeading
                                            key={field.id}
                                            field={field}
                                            index={i}
                                            setActiveIndex={setActiveIndex}
                                            onOpen={onOpen}
                                        />
                                    );
                                case 'textImageLeft':
                                    return (
                                        <TextWithImage
                                            key={field.id}
                                            field={field}
                                            index={i}
                                            setActiveIndex={setActiveIndex}
                                            onOpen={onOpen}
                                            align='left'
                                        />
                                    );
                                case 'textImageRight':
                                    return (
                                        <TextWithImage
                                            key={field.id}
                                            field={field}
                                            index={i}
                                            setActiveIndex={setActiveIndex}
                                            onOpen={onOpen}
                                            align='right'
                                        />
                                    );
                            }
                        })}
                </Flex>

                <SelectModal
                    onClose={onClose}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    addBlock={addBlock}
                />
            </Flex>
        </PageEditorContext.Provider>
    );
};

export default PageEditor;
