import React, { useEffect } from "react";
import { LinkButton, ListWrapper } from "../../Nucleus";
import { Box, Button, Text, Image } from "@chakra-ui/react";
import setup from "./setup";
import { gql } from "@apollo/client";
import { FiEdit } from "react-icons/fi";
import { useContext } from "react";
import { NucleusContext } from "../../Nucleus/Core";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../Nucleus/state/auth";

const FETCHDATA = gql`
  query BranchOfUser($branchOfUserId: String) {
    branchOfUser(id: $branchOfUserId) {
      name
      _id
      images
    }
  }
`;

const DELETE = gql`
  mutation BranchDelete($input: [String]) {
    branchDelete(input: $input) {
      total
    }
  }
`;
const CREATE = gql`
  mutation Mutation($record: CreateOnebranchInput!) {
    branchCreateOne(record: $record) {
      recordId
      record {
        name
        images
        _id
      }
    }
  }
`;

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const List = () => {
  // Get current user
  const user = useRecoilValue(authAtom);
  const { links } = useContext(NucleusContext);

  useEffect(() => {
    if (user?.currentUser?.branch) {
      window.location.href = `/branches/edit/${user.currentUser.branch}`;
    }
  }, [user]);

  const columns = [
    {
      id: "_id",
      disableSortBy: true,
      Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button
            variant='link'
            size='sm'
            color='secondary'
            rounded='md'
            fontSize='18px'
          >
            <FiEdit />
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: "Branch",
      accessor: "name",
    },
  ];

  const filters = [];

  if (!user.currentUser) return null;

  // In default filter we filter to only find the branch for the user that is logged in
  // using the id we get from the 'user' object which we get from the authAtom

  return (
    <Box>
      <ListWrapper
        setup={setup}
        columns={columns}
        extraMenu={extraMenu}
        gqlFetch={FETCHDATA}
        customVars={{ branchOfUserId: user?.currentUser?.id }}
        gqlCreate={CREATE}
        gqlDelete={DELETE}
        defaultFilter={{}}
        defaultSort={{}}
        filters={[]}
        showTopPagination={false}
      />
    </Box>
  );
};

export default List;
