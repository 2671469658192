import React from 'react';
import {
    Flex,
    Grid,
    Modal,
    Image,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    Button,
    Text,
} from '@chakra-ui/react';

const Select = ({ children, onClick, image }) => (
    <Flex
        w='100%'
        rounded='5px'
        onClick={onClick}
        border='1px'
        borderColor='gray.300'
        p='10px'
        align='center'
        _hover={{ cursor: 'pointer', borderColor: 'gray.500' }}
    >
        <Image src={image} w='100px' opacity={0.3} mr='20px' />
        <Text fontSize='16px'>{children}</Text>
    </Flex>
);

const SelectModal = ({ isOpen, onClose, addBlock }) => {
    return (
        <Modal size='xl' isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Page Block</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Grid templateColumns='repeat(1fr)' gap='20px'>
                        <Select
                            onClick={() => addBlock('textWithHeading')}
                            image='/images/block-text.svg'
                        >
                            Text with heading
                        </Select>
                        <Select
                            onClick={() => addBlock('textImageRight')}
                            image='/images/block-image-right.svg'
                        >
                            Text & Right Image
                        </Select>
                        <Select
                            onClick={() => addBlock('textImageLeft')}
                            image='/images/block-image-left.svg'
                        >
                            Text & Left Image
                        </Select>
                    </Grid>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button variant='ghost'>Secondary Action</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SelectModal;
