import React from "react";
import { AutoFields, EditView } from "../../Nucleus";
import { gql, useLazyQuery } from "@apollo/client";
import setup from "./setup";

const FETCH = gql`
  query GetBranch($id: MongoID!) {
    branchById(_id: $id) {
      name
      images
      interval
      transition
      animation
    }
  }
`;

const EDIT = gql`
  mutation EditBranch(
    $record: UpdateOnebranchInput!
    $filter: FilterUpdateOnebranchInput
  ) {
    branchUpdate(record: $record, filter: $filter) {
      record {
        name
        images
      }
    }
  }
`;

const CREATE = gql`
  mutation BranchCreateOne($record: JSON) {
    branchCreateOne(record: $record) {
      name
      images
      _id
    }
  }
`;

const EditForm = () => {
  const [fetchData, { data }] = useLazyQuery(FETCH);
  const fields = [
    {
      group: `Slides for ${data?.branchById?.name} branch`,
      tab: "Slides",
      fields: [
        {
          name: "images",
          component: "file",
          label: "Select File",
        },
      ],
    },
    {
      group: "Settings",
      tab: "Settings",
      fields: [
        {
          name: "name",
          component: "text",
          placeholder: "Enter branch Name",
          label: "Branch Name",
          rules: { required: true },
        },

        {
          name: "interval",
          component: "number",
          placeholder: "6000",
          label: "Slide Duration",
          help: "in milliseconds",
        },
        {
          name: "transition",
          component: "number",
          placeholder: "1000",
          label: "Transition Duration",
          help: "in milliseconds",
        },

        {
          name: "animation",
          component: "select",
          options: [
            {
              label: "Fade",
              value: "fade",
            },
            {
              label: "Slide",
              value: "slide",
            },
          ],
          placeholder: "Select animation",
          label: "Transition Animatino",
          labelKey: "label",
          valueKey: "value",
          selectValue: "value",
        },
      ],
    },
  ];

  return (
    <EditView
      noListView={true}
      setup={setup}
      gqlFetch={FETCH}
      gqlEdit={EDIT}
      gqlCreate={CREATE}
      fetchData={fetchData}
      data={data}
    >
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
