import React from 'react'
import { useEffect, memo, useState, Fragment } from 'react'
import {
   useTable,
   usePagination,
   useSortBy,
   useResizeColumns,
   useRowSelect,
   useExpanded
} from 'react-table'

import {
   Box,
   Flex,
   Icon,
   Input,
   Text,
   Button,
   Select,
   Checkbox,
   IconButton,
   Spinner,
   useColorModeValue,
   Table,
   Thead,
   Tr,
   Td,
   Th,
   Container,
   Tbody
} from '@chakra-ui/react'

import { BsArrowsExpand, BsArrowsCollapse } from 'react-icons/bs'
import { MdErrorOutline } from 'react-icons/md'
import { HiChevronDown, HiChevronRight } from 'react-icons/hi'

import {
   useTableStyles,
   tableCellStyles,
   tableHeadCellStyles,
   tableRowStyles
} from './styles'
import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa'
import ScrollContainer from '../ScrollContainer'

const LoaderComponent = () => (
   <Flex
      p={5}
      position='absolute'
      top='30px'
      bg='global.elementBg'
      left={0}
      w='100%'
      h='100%'
      justify='center'
      align='center'
      zIndex={100}
      opacity={0.6}
   >
      <Spinner color='primary' size='30px' />
   </Flex>
)

const ErrorComponent = memo(() => (
   <Flex
      p={5}
      position='absolute'
      top='40px'
      bg='global.elementBg'
      left={0}
      w='100%'
      h='100%'
      justify='center'
      align='flex-start'
      zIndex={100}
      opacity={1}
   >
      <Flex align='center'>
         <Icon mr='10px' fontSize='22px' color='error'>
            <MdErrorOutline />
         </Icon>{' '}
         <Text fontSize='18px'>Error fetching data</Text>
      </Flex>
   </Flex>
))

const HeaderColumn = memo(
   ({ column, isBordered, size, isSorted, isSortedDesc, disableSortBy }) => {
      const color = useColorModeValue('gray.800', 'gray.100')
      return (
         <Th
            {...tableHeadCellStyles(
               { isBordered, size, isSorted },
               column.cellProps
            )}
            {...column.getHeaderProps()}
            _hover={{ cursor: disableSortBy ? 'default' : 'pointer ' }}
            style={{ position: 'sticky', zIndex: 10 }}
            textTransform='none'
            letterSpacing='normal'
            color={color}
            borderColor='gray.200'
         >
            <Flex
               align='center'
               justify='flex-start'
               w='100%'
               textAlign='left'
               {...column.getSortByToggleProps()}
            >
               {column.render('Header')}

               <Box ml='auto'>
                  {isSorted ? (
                     isSortedDesc ? (
                        <FaSortAmountDownAlt />
                     ) : (
                        <FaSortAmountDown />
                     )
                  ) : (
                     ''
                  )}
               </Box>
            </Flex>
         </Th>
      )
   }
)

const TableCell = memo(({ cell, size }) => {
   return (
      <Td
         {...tableCellStyles({ size }, cell.column.cellProps)}
         {...cell.getCellProps()}
         as='td'
         borderColor='gray.200'
      >
         {cell.render('Cell')}
      </Td>
   )
})

const TableRow = ({
   row,
   size,
   onRowSelect,
   isExpandable,
   toggleRowExpanded
}) => {
   return (
      <Tr
         {...row.getRowProps()}
         {...tableRowStyles({ isSelected: row.isSelected })}
         _hover={{ bg: !row.isSelected && 'tables.rowHoverBg' }}
         as='tr'
      >
         {isExpandable && (
            <Td
               {...tableCellStyles({ size })}
               textOverflow='normal'
               px={!isExpandable && '0px'}
               style={{ minWidth: 45, width: 45 }}
            >
               <IconButton
                  rounded='md'
                  variant='link'
                  fontSize='1.4em'
                  onClick={() => toggleRowExpanded(row.id)}
               >
                  {row.isExpanded ? <HiChevronDown /> : <HiChevronRight />}
               </IconButton>
            </Td>
         )}

         <Td
            {...tableCellStyles({ size })}
            style={{ minWidth: 45, width: 45 }}
            borderColor='gray.200'
         >
            <IndeterminateCheckbox
               onRowSelect={onRowSelect}
               {...row.getToggleRowSelectedProps()}
            />
         </Td>

         {row.cells.map((cell, i) => {
            return <TableCell key={`cell-${i}`} cell={cell} size={size} />
         })}
      </Tr>
   )
}

const IndeterminateCheckbox = memo(
   ({ indeterminate, checked, onRowSelect, ...rest }) => {
      return (
         <Flex
            align='center'
            justify='center'
            w='100%'
            h='100%'
            opacity={onRowSelect === null && 0.7}
            style={{ minWidth: 5 }}
         >
            <Checkbox
               isIndeterminate={indeterminate}
               isChecked={checked}
               size='lg'
               isDisabled={onRowSelect === null && true}
               {...rest}
            />
         </Flex>
      )
   }
)

const TableComponent = (props) => {
   const {
      columns,
      data,
      showHeader,
      loading,
      onChangePageSize,
      onChangePage,
      onRowSelect = null,
      onSortSelect,
      manualPagination = true,
      hidePagination = false,
      accessor,
      error,
      isExpandable,
      showTopPagination,
      disableSortBy,
      size
   } = props

   const {
      getTableProps,
      headerGroups,
      rows,
      page,
      prepareRow,
      selectedFlatRows,
      nextPage,
      previousPage,
      setPageSize,
      getToggleRowSelectedProps,
      getToggleAllRowsSelectedProps,
      canPreviousPage,
      canNextPage,
      isAllRowsExpanded,
      toggleAllRowsExpanded,
      toggleRowExpanded,
      state: { pageSize, selectedRowIds, sortBy }
   } = useTable(
      {
         columns,
         manualRowSelectedKey: 'isSelected',
         manualExpandedKey: 'isExpanded',
         data:
            process.env.REACT_APP_USE_GRAPHQL === 'true'
               ? data?.data.items
               : data?.data,
         manualSortBy: true,
         disableSortBy: disableSortBy,
         manualPagination: manualPagination,
         initialState: { selectedFlatRows: [] }
      },
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
      useResizeColumns
   )

   /* eslint-disable */
   useEffect(() => {
      if (onRowSelect !== null && selectedFlatRows) {
         onRowSelect(selectedFlatRows)
      }
   }, [selectedRowIds])

   useEffect(() => {
      if (onSortSelect) {
         onSortSelect(sortBy)
      }
   }, [sortBy])

   const rowMap = manualPagination ? rows : page

   // Render the UI for your table
   return (
      <Fragment>
         {!hidePagination && showTopPagination && (
            <Pagination
               meta={
                  process.env.REACT_APP_USE_GRAPHQL
                     ? data.data.pageInfo
                     : data.meta
               }
               onChangePage={onChangePage}
               manualPagination={manualPagination}
               canPreviousPage={canPreviousPage}
               pageSize={pageSize}
               setPageSize={setPageSize}
               onChangePageSize={onChangePageSize}
               canNextPage={canNextPage}
               nextPage={nextPage}
               previousPage={previousPage}
            />
         )}
         <ScrollContainer>
            <Table {...getTableProps()} {...useTableStyles(props)} size={size}>
               <Thead mb='5px'>
                  {showHeader &&
                     headerGroups?.map((headerGroup, i) => (
                        <Tr
                           key={`header-${i}`}
                           {...headerGroup.getHeaderGroupProps()}
                           as='tr'
                        >
                           {isExpandable && (
                              <Th
                                 {...tableHeadCellStyles({
                                    isBordered: props.isBordered,
                                    size: props.size,
                                    isSorted: false
                                 })}
                                 position='sticky'
                                 top='0px'
                                 zIndex={10}
                                 px={!isExpandable && '0px'}
                                 style={{ minWidth: 45, width: 45 }}
                                 as='td'
                              >
                                 <IconButton
                                    variant='link'
                                    fontSize='1.2em'
                                    onClick={() => toggleAllRowsExpanded()}
                                 >
                                    {isAllRowsExpanded ? (
                                       <BsArrowsCollapse />
                                    ) : (
                                       <BsArrowsExpand />
                                    )}
                                 </IconButton>
                              </Th>
                           )}

                           <Th
                              {...tableHeadCellStyles({
                                 isBordered: props.isBordered,
                                 size: props.size,
                                 isSorted: false
                              })}
                              position='sticky'
                              top='0px'
                              zIndex={10}
                              style={{ minWidth: 45, width: 45 }}
                              as='td'
                           >
                              <IndeterminateCheckbox
                                 onRowSelect={onRowSelect}
                                 {...getToggleAllRowsSelectedProps()}
                              />
                           </Th>

                           {headerGroup.headers?.map((column, i) => (
                              <HeaderColumn
                                 key={`headerCol-${i}`}
                                 column={column}
                                 isBordered={props.isBordered}
                                 size={props.size}
                                 isSorted={column.isSorted}
                                 isSortedDesc={column.isSortedDesc}
                                 disableSortBy={disableSortBy}
                              />
                           ))}
                        </Tr>
                     ))}
               </Thead>
               {loading && <LoaderComponent />}
               {error && <ErrorComponent />}
               <Tbody>
                  {rowMap &&
                     rowMap.map((row, i) => {
                        prepareRow(row)
                        return (
                           <TableRow
                              key={`row-${i}`}
                              row={row}
                              accessor={accessor}
                              isSelected={row.isSelected}
                              size={props.size}
                              getToggleRowSelectedProps={
                                 getToggleRowSelectedProps
                              }
                              onRowSelect={onRowSelect}
                              isExpandable={isExpandable}
                              toggleRowExpanded={toggleRowExpanded}
                           />
                        )
                     })}
               </Tbody>
            </Table>
         </ScrollContainer>
         {!hidePagination && (
            <Pagination
               meta={data.meta}
               onChangePage={onChangePage}
               manualPagination={manualPagination}
               canPreviousPage={canPreviousPage}
               pageSize={pageSize}
               setPageSize={setPageSize}
               onChangePageSize={onChangePageSize}
               canNextPage={canNextPage}
               nextPage={nextPage}
               previousPage={previousPage}
            />
         )}
      </Fragment>
   )
}

TableComponent.defaultProps = {
   showHeader: true,
   isBordered: true,
   size: 'sm',
   onRowSelect: null,
   showTopPagination: false
}

const Pagination = ({
   meta,
   onChangePage,
   manualPagination,
   canPreviousPage,
   pageSize,
   setPageSize,
   onChangePageSize,
   canNextPage,
   nextPage,
   previousPage
}) => {
   const [pagination, setPagination] = useState(meta ? meta.current_page : 1)

   useEffect(() => {
      if (meta && meta.current_page) {
         setPagination(meta.current_page)
      }
   }, [meta])

   const tableBorder = useColorModeValue('gray.300', 'gray.700')

   return (
      <Container
         display='flex'
         justifyContent='center'
         alignItems='center'
         p={2}
         mt='auto'
         borderTop='1px'
         borderColor={tableBorder}
         position='sticky'
         bottom={0}
         variant='card'
         w='100%'
         maxWidth='none'
         rounded={0}
         boxShadow='none'
      >
         <Button
            onClick={() => {
               previousPage()
               onChangePage(onChangePage ? meta?.current_page - 1 : null)
            }}
            disabled={
               manualPagination
                  ? meta?.current_page === meta?.from
                  : !canPreviousPage
            }
            variant='ghost'
            mr='45px'
            size='sm'
         >
            Previous
         </Button>{' '}
         <Flex align='center'>
            <Box as='span' mr='10px' fontSize='md'>
               Page{' '}
            </Box>
            <Input
               type='number'
               value={pagination}
               min={1}
               size='sm'
               max={meta?.last_page}
               defaultValue={meta?.current_page}
               onBlur={(e) => {
                  let v = e.target.value
                  if (e.target.value > meta?.last_page) {
                     v = meta?.last_page
                     setPagination(meta?.last_page)
                  }
                  if (e.target.value < 1) {
                     v = 1
                     setPagination(1)
                  }
                  onChangePage(onChangePage ? v : null)
               }}
               w={70}
               onChange={(e) => setPagination(e.target.value)}
            />
            <Box ml='10px' fontSize='md'>
               of {meta?.last_page}
            </Box>
         </Flex>
         <Select
            ml='10px'
            maxWidth='150px'
            size='sm'
            value={pageSize}
            onChange={(e) => {
               setPageSize(Number(e.target.value))
               onChangePageSize(e.target.value)
            }}
         >
            {[10, 20, 30, 40, 50, 100, 200].map((pageSize) => (
               <option key={pageSize} value={pageSize}>
                  Show {pageSize}
               </option>
            ))}
         </Select>
         <Button
            onClick={() => {
               nextPage()
               onChangePage(onChangePage ? meta?.current_page + 1 : null)
            }}
            disabled={
               manualPagination
                  ? meta?.current_page === meta?.last_page
                  : !canNextPage
            }
            size='sm'
            variant='ghost'
            ml='45px'
         >
            Next
         </Button>{' '}
      </Container>
   )
}

export default TableComponent
