import React from 'react';
import InputMask from 'react-input-mask';
import { Input } from '@chakra-ui/react';

const Mask = (props) => {
    const { onFocus, onChange, onBlur, value, children, type, mask, ...rest } =
        props;
    return (
        <InputMask
            mask={mask}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        >
            {(inputProps) => (
                <Input
                    {...inputProps}
                    required={false}
                    isRequired={false}
                    minWidth={type === 'number' ? '100px' : '200px'}
                    type={type}
                    {...rest}
                />
            )}
        </InputMask>
    );
};

export default Mask;
