import React from "react";
import Cloudinary from "./Cloudinary";
import Standard from "./Standard";
import Uploader from "./Uploader";
const FileUpload = (props) => {
  return process.env.REACT_APP_UPLOAD_METHOD === "cloudinary" ? (
    <Cloudinary {...props} />
  ) : (
    <Standard {...props} />
  );
};

export default FileUpload;
